
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { required, minValue, maxValue, sameAs } from 'vuelidate/dist/validators.min';

import AccountColorMarker from 'Common/AccountColorMarker.vue';
import SubscriptionInput from 'Components/Earn/components/SubscriptionInput.vue';
import { allowedEarnPlacements, IAggregatedProduct, supportedDurations } from 'Store/v2/Earn';
import StakingApi from 'Apis/Staking';
import GetQuotaRequest from 'Entities/staking/GetQuotaRequest';
import ApiError from 'Entities/ApiError';
import PurchaseRequest from 'Entities/staking/PurchaseRequest';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Account from 'Entities/privatePresenter/Account';
import Button from 'Control/Button.vue';
import Checkbox from 'Control/Checkbox.vue';
import Shift from 'UI/Shift.vue';
import Faq from 'UI/Faq.vue';

interface Data {
    MILLISECONDS_IN_DAY: number;
    subscriptionDate: null | Date;
    product: any;
    selectedDuration: number;
    currentQuota: number;
    inputValue: number;
    isAgreedWithConditions: boolean;
    allowedEarnPlacements: any;
    hasAgreedErrors: boolean;
    supportedDurations: number[];
}

interface Methods {
    setInputValue: (data: number) => void;
    toggleConditions: () => void;
    setActiveDurationValue: (data: number) => void;
    getQuota: () => void;
    dateMaker: (data: Date) => string;
    subscribe: () => void;
    openWindow: (data: string) => void;
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    availableBalance: number;
    fullPlacementName: string;
    valueDate: number | null;
    interestEndDate: Date | null;
    duration: Map<number, { apr: number; minAmount: number; }>
    currentApr: number;
    currentMinValue: number;
    allProducts: IAggregatedProduct[];
    productAsset: string;
    asset: string;
    placementName: string;
    quantityErrorText: string;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Faq,
        AccountColorMarker,
        SubscriptionInput,
        Button,
        Checkbox,
        Shift,
    },
    data() {
        return {
            MILLISECONDS_IN_DAY: 86400000,
            subscriptionDate: null,
            product: null,
            selectedDuration: 30,
            currentQuota: 0,
            inputValue: 0,
            isAgreedWithConditions: false,
            allowedEarnPlacements,
            hasAgreedErrors: false,
            supportedDurations,
        };
    },
    validations() {
        return {
            isAgreedWithConditions: {
                sameAs: sameAs(() => true),
            },
            inputValue: {
                required,
                minValue: minValue(this.currentMinValue),
                maxValue: maxValue(this.availableBalance),
            },
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        quantityErrorText() {
            if (!this.$v.inputValue.minValue) {
                return `Value must be greater than ${this.currentMinValue}`;
            }
            if (!this.$v.inputValue.maxValue) {
                return `Value must be lower than ${this.availableBalance}`;
            }
            return '';
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        availableBalance() {
            return Number(this.$store.getters['Balances/GET_BALANCES'].find((b) => b.assetSymbol === this.asset
                && b.placementName === this.placementName
                && b.accountId === this.activeAccount.id)?.free ?? 0);
        },
        fullPlacementName() {
            return this.allowedEarnPlacements.find((p) => p.alias === this.placementName)?.name ?? '';
        },
        valueDate() {
            try {
                return new Date(this.subscriptionDate!.valueOf() + this.MILLISECONDS_IN_DAY).setHours(0, 0, 0, 0);
            } catch {
                return null;
            }
        },
        interestEndDate() {
            try {
                return new Date(this.valueDate!.valueOf() + (this.MILLISECONDS_IN_DAY * this.selectedDuration));
            } catch {
                return null;
            }
        },
        duration() {
            return this.product?.duration ?? new Map<number, { apr: number; minAmount: number; }>();
        },
        currentApr() {
            return this.duration.get(this.selectedDuration)?.apr ?? 0;
        },
        currentMinValue() {
            return this.duration.get(this.selectedDuration)?.minAmount ?? 0;
        },
        allProducts() {
            return this.$store.state.Earn.products;
        },
        productAsset() {
            return this.$route.query.productAsset as string ?? '';
        },
        asset() {
            return this.product?.asset ?? '';
        },
        placementName() {
            return this.product?.placementName ?? '';
        },
    },
    methods: {
        setInputValue(value) {
            this.inputValue = value;
            this.$v.inputValue.$reset();
        },
        toggleConditions() {
            this.isAgreedWithConditions = !this.isAgreedWithConditions;
        },
        setActiveDurationValue(value) {
            if (!this.duration.has(value)) {
                return;
            }
            this.selectedDuration = value;
        },
        async getQuota() {
            try {
                const { data: quota } = await StakingApi.getQuota(new GetQuotaRequest({
                    placementName: this.placementName,
                    asset: this.asset,
                    accountId: this.activeAccount.id,
                    duration: this.selectedDuration,
                }));
                this.currentQuota = quota.quotaLeft;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting quota');
                }
            }
        },
        dateMaker(date) {
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        async subscribe() {
            this.hasAgreedErrors = false;
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await StakingApi.purchase(new PurchaseRequest({
                        accountId: this.activeAccount.id,
                        placementName: this.placementName,
                        amount: String(this.inputValue),
                        asset: this.asset,
                        duration: this.selectedDuration,
                    }));
                    await this.$router.push('/earn').catch(() => { /* navigation error */ });
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting quota');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else if (this.$v.isAgreedWithConditions.$invalid) {
                this.hasAgreedErrors = true;
            }
        },
        openWindow(link) {
            window.open(link);
        },
    },
    async mounted() {
        const { productAsset, selectedDuration } = this.$route.query;
        this.product = this.allProducts.find(({ asset }) => asset === productAsset);
        this.selectedDuration = Number(selectedDuration);
        this.subscriptionDate = new Date();
        if (this.product) {
            await this.getQuota();
        }
    },
    watch: {
        async selectedDuration() {
            await this.getQuota();
        },
        async allProducts() {
            this.product = this.allProducts.find(({ asset }) => asset === this.productAsset);
            if (this.product) {
                await this.getQuota();
            }
        },
    },
});
